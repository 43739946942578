import React from "react";
import { Routes, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import MobileMenu from "./components/MobileMenu";
import BrandLogoSlider from "./components/BrandLogoSlider";

import Gallery from "./pages/Gallery";
import HomePage from "./home/Home";
import Vision from "./pages/Vision";
import Background from "./pages/Background";
import Orgonogram from "./pages/Orgonogram";
import Principal from "./pages/Principal";
import OurTeam from "./pages/OurTeam";
import Departments from "./pages/Departments";
import Programs from "./pages/Programs.js";
import Online from "./pages/Online";
import HowToApply from "./pages/HowToApply";
import ShortCourses from "./pages/ShortCourses";
import Students from "./pages/Students";
import IctServices from "./pages/IctServices";
import Library from "./pages/Library";
import Apiary from "./pages/Apiary";
import Sawmill from "./pages/Sawmill";
import TreeNursery from "./pages/TreeNursery";
import ReserachAndConsultancy from "./pages/ResearchAndConsultancy";
import TrainingForest from "./pages/TrainingForest";
import NoMAtch from "./pages/404";

import ProjectDetails from "./project/ProjectDetails";
import BlogLeftSidebar from "./blog/BlogLeftSidebar";
import BlogRightSidebar from "./blog/BlogRightSidebar";
import BlogDetailsLeftSidebar from "./blog/BlogDetailsLeftSidebar";
import BlogDetailsRightSidebar from "./blog/BlogDetailsRightSidebar";
import Contact from "./pages/Contact";
import FeeStructure from "./pages/FeeStructure";
import Leadership from "./pages/Leaders";
import AdvisoryBoard from "./pages/Board";
import PDFViewer from "./components/PDFViewer";
function WebRoutes() {
  return (
    <>
      {/* Navigation bar */}
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/vision" element={<Vision />} />
        <Route path="/background" element={<Background />} />
        <Route path="/orgonogram" element={<Orgonogram />} />
        <Route path="/principal" element={<Principal />} />
        <Route path="/team" element={<OurTeam />} />
        <Route path="/departments" element={<Departments />} />
        <Route path="/programs" element={<Programs />} />
        <Route path="/online" element={<Online />} />
        <Route path="/how-to-apply" element={<HowToApply />} />
        <Route path="/short-courses" element={<ShortCourses />} />
        <Route path="/students" element={<Students />} />
        <Route path="/ict-services" element={<IctServices />} />
        <Route path="/library" element={<Library />} />

        <Route path="/apiary" element={<Apiary />} />
        <Route path="/pdf-viewer" element={<PDFViewer />} />
        <Route path="/sawmill" element={<Sawmill />} />
        <Route path="/tree-nursery" element={<TreeNursery />} />
        <Route path="/project-details" element={<ProjectDetails />} />
        <Route path="/news-left-sidebar" element={<BlogLeftSidebar />} />
        <Route path="/blog-right-sidebar" element={<BlogRightSidebar />} />
        <Route
          path="/news-details-left-sidebar"
          element={<BlogDetailsLeftSidebar />}
        />
        <Route
          path="/blog-details-right-sidebar"
          element={<BlogDetailsRightSidebar />}
        />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/training-forest" element={<TrainingForest />} />
        <Route
          path="/research-and-consultancy"
          element={<ReserachAndConsultancy />}
        />
        <Route path="/fee-structure" element={<FeeStructure />} />
        <Route path="/leaders" element={<Leadership />} />
        <Route path="/board" element={<AdvisoryBoard />} />
        <Route path="*" element={<NoMAtch />} />
      </Routes>
      <BrandLogoSlider />
      <Footer />
      <MobileMenu />
    </>
  );
}

export default WebRoutes;
