// MarqueeComponent.js
import React from "react";
import "../assets/scss/3-elements/_marquee.scss";

const MarqueeComponent = () => {
  return (
    <div className="marquee">
      <p>
        Application for Certificate and Diploma Academic Year 2024/2025 is Now
        Open
      </p>
    </div>
  );
};

export default MarqueeComponent;
