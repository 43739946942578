import React, { Component } from "react";
import { Link } from "react-router-dom";

class MobileMenu extends Component {
  state = {
    active: false,
  };

  toggleMobileMenu = () => {
    this.setState({
      active: !this.state.active,
    });
  };

  componentDidMount() {
    var offCanvasNav = document.getElementById("offcanvas-navigation");
    var offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        "<span class='menu-expand'><i></i></span>",
      );
    }

    var menuExpand = offCanvasNav.querySelectorAll(".menu-expand");
    var numMenuExpand = menuExpand.length;

    function sideMenuExpand() {
      if (this.parentElement.classList.contains("active") === true) {
        this.parentElement.classList.remove("active");
      } else {
        for (let i = 0; i < numMenuExpand; i++) {
          menuExpand[i].parentElement.classList.remove("active");
        }
        this.parentElement.classList.add("active");
      }
    }

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", sideMenuExpand);
    }
  }

  render() {
    return (
      <div>
        {/*=======  offcanvas mobile menu  =======*/}
        <div
          className={`offcanvas-mobile-menu ${this.state.active ? "active" : ""} `}
          id="mobile-menu-overlay"
        >
          <a
            href="#/"
            className="offcanvas-menu-close"
            id="mobile-menu-close-trigger"
            onClick={this.toggleMobileMenu}
          >
            <i className="ion-android-close" />
          </a>
          <div className="offcanvas-wrapper">
            <div className="offcanvas-inner-content">
              <div className="offcanvas-mobile-search-area">
                <form action="#">
                  <input type="search" placeholder="Search ..." />
                  <button type="submit">
                    <i className="fa fa-search" />
                  </button>
                </form>
              </div>
              <nav className="offcanvas-navigation" id="offcanvas-navigation">
                <ul>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/home`}>HOME</Link>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to={`${process.env.PUBLIC_URL}`}>ABOUT</Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to={`/vision`}>Vision, Mission, Core Values</Link>
                      </li>
                      <li>
                        <Link to={`/background`}>History and Major Roles</Link>
                      </li>
                      <li>
                        <Link to={`/orgonogram`}>Organization Structure</Link>
                      </li>
                      <li>
                        <Link to={`/principal`}>
                          Message from the Principal
                        </Link>
                      </li>
                      <li>
                        <Link to={`/leaders`}>FTI Top Leadership</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to={`${process.env.PUBLIC_URL}/projects`}>
                      ADMISSION
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to={`/programs`}>Programs Offered</Link>
                      </li>
                      <li>
                        <Link to={`/how-to-apply`}>How to Apply</Link>
                      </li>
                      <li>
                        <Link to={`/online`}>Online Application</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to={`${process.env.PUBLIC_URL}/projects`}>
                      ACADEMICS
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to={`/departments`}>Academic Departments</Link>
                      </li>
                      <li>
                        <Link to={`/short-courses`}>Short Courses</Link>
                      </li>
                      <li>
                        <Link
                          to="https://saris.maliasili.go.tz"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          SARIS
                        </Link>
                      </li>
                      <li>
                        <Link to={`/students`}>Students' Welfare</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to={`${process.env.PUBLIC_URL}/projects`}>
                      FACILITIES
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/projects`}>
                          ICT Services
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/project-details`}>
                          Library
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/project-details`}>
                          Apiary
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/project-details`}>
                          Sawmill
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/project-details`}>
                          Tree Nursery
                        </Link>
                      </li>
                      <li>
                        <Link to={`${process.env.PUBLIC_URL}/project-details`}>
                          Training Forest
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/news-left-sidebar`}>
                      {" "}
                      NEWS AND EVENTS
                    </Link>
                  </li>
                  <li className="menu-item-has-children">
                    <Link to={`${process.env.PUBLIC_URL}/news-left-sidebar`}>
                      GALLERY
                    </Link>
                    <ul className="sub-menu">
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/news-left-sidebar`}
                        >
                          All
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/blog-right-sidebar`}
                        >
                          Image Gallery
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={`${process.env.PUBLIC_URL}/news-details-left-sidebar`}
                        >
                          Video Gallery
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <Link to={`${process.env.PUBLIC_URL}/contact-us`}>
                      CONTACT
                    </Link>{" "}
                  </li>
                </ul>
              </nav>
              <div className="offcanvas-widget-area">
                <div className="off-canvas-contact-widget">
                  <div className="header-contact-info">
                    <ul className="header-contact-info__list">
                      <li>
                        <i className="ion-android-phone-portrait" />{" "}
                        <a href="tel://12452456012">(1245) 2456 012 </a>
                      </li>
                      <li>
                        <i className="ion-android-mail" />{" "}
                        <a href="mailto:info@yourdomain.com">
                          info@yourdomain.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/*Off Canvas Widget Social Start*/}
                <div className="off-canvas-widget-social">
                  <a href="#/" title="Facebook">
                    <i className="fa fa-facebook" />
                  </a>
                  <a href="#/" title="Twitter">
                    <i className="fa fa-twitter" />
                  </a>
                  <a href="#/" title="LinkedIn">
                    <i className="fa fa-linkedin" />
                  </a>
                  <a href="#/" title="Youtube">
                    <i className="fa fa-youtube-play" />
                  </a>
                  <a href="#/" title="Vimeo">
                    <i className="fa fa-vimeo-square" />
                  </a>
                </div>
                {/*Off Canvas Widget Social End*/}
              </div>
            </div>
          </div>
        </div>
        {/*=======  End of offcanvas mobile menu  =======*/}
      </div>
    );
  }
}

export default MobileMenu;

