import React, { Component } from "react";
import "../assets/scss/3-elements/_vision.scss";

const vision =
  "To being a center of excellence for capacity building in Technical Forest Management and the Environment in Africa.";
const mission =
  "Provide adequate knowledge, skills and attitude to our clients for sustainable development of forest resources and the environment.";
const coreValues = [
  {
    title: "Professionalism",
    description:
      "The Institute is committed to adhering to high levels of integrity, respect, impartiality, courtesy, timeliness, effectiveness, and efficiency in the course of discharging all activities.",
  },
  {
    title: "Accountability",
    description:
      "In sharing information with all stakeholders, exercising diligence in all duties assigned, and ensuring optimum use of resources.",
  },
  {
    title: "Commitment",
    description:
      "In providing high quality, efficient, excellent service to customers and improvement and innovation.",
  },
  {
    title: "Team Work",
    description:
      "By capitalizing on working together and integrating diverse disciplines.",
  },
  {
    title: "Integrity",
    description:
      "Through demonstrating a high level of integrity, guided by rules, regulations, and procedures in discharging the services.",
  },
  {
    title: "Equality",
    description:
      "In providing services in a range of more flexible ways to ensure genuine equality of access and opportunity for everyone who inquires about our services.",
  },
];

class Vision extends Component {
  render() {
    return (
      <div className="vision-container">
        <div className="content-section">
          <div className="section">
            <h2>Vision</h2>
            <p>{vision}</p>
          </div>

          <div className="section">
            <h2>Mission</h2>
            <p>{mission}</p>
          </div>

          <div className="section">
            <h2>Core Values</h2>
            <ul>
              {coreValues.map((value, index) => (
                <li key={index} className="core-value-item">
                  <h3>{value.title}</h3>
                  <p>{value.description}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default Vision;
