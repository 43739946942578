import React, { Component } from "react";
import { Link, Outlet } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import { FacebookOutlined } from "@mui/icons-material";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YoutubeIcon from "@mui/icons-material/YouTube";

class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleScroll = this.handleScroll.bind(this);

    this.mobileMenuElement = React.createRef();
  }

  activeMobileMenu = () => {
    this.mobileMenuElement.current.toggleMobileMenu();
  };

  handleScroll() {
    if (this.mount) {
      this.setState({ scroll: window.scrollY });
    }
  }

  componentDidMount() {
    this.mount = true;
    const el = document.querySelector("nav");
    this.setState({ top: el.offsetTop, height: el.offsetHeight });
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate() {
    this.state.scroll > this.state.top
      ? (document.body.style.paddingTop = `${this.state.height}px`)
      : (document.body.style.paddingTop = 0);
  }

  componentWillUnmount() {
    this.mount = false;
  }

  render() {
    return (
      <div>
        {/*====================  header area ====================*/}
        <div
          className={`header-area header-sticky header-sticky--default ${this.state.scroll > this.state.top ? "is-sticky" : ""}`}
        >
          <div className="header-area__desktop header-area__desktop--default">
            {/*=======  header top bar  =======*/}
            <div className="header-top-bar" align="center">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    {/* top bar left */}
                    <div className="top-bar-left-wrapper">
                      <div className="social-links social-links--white-topbar d-inline-block">
                        <ul>
                          <li>
                            <a href="https://twitter.com/ForestryFti">
                              <TwitterIcon fontSize="large" />
                            </a>
                          </li>
                          <li>
                            <a href="https://www.facebook.com">
                              <FacebookOutlined fontSize="large" />
                            </a>
                          </li>
                          <li>
                            <a href="https://www.instagram.com/forestry_fti">
                              <InstagramIcon fontSize="large" />
                            </a>
                          </li>
                          <li className="d-none d-md-block">
                            <a href="https://www.youtube.com">
                              <YoutubeIcon fontSize="large" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header top bar  =======*/}
            {/*=======  header info area  =======*/}
            <div className="header-info-area">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="header-info-wrapper align-items-center">
                      {/* logo */}
                      <div className="logo">
                        <Link to={`/`}>
                          <img
                            src="assets/img/logo/logo.png"
                            className="img-fluid"
                            alt="Logo"
                          />
                        </Link>
                      </div>
                      <div className="college d-none d-md-block">
                        <h2>Ministry of Natural Resource and Tourism</h2>
                        <h1 className="college-names">
                          Forestry Training Institute Olmotonyi
                        </h1>
                      </div>
                      <div className="logo d-none d-md-block">
                        <Link to={`/`}>
                          <img
                            src="assets/img/logo/logo2.png"
                            className="img-fluid"
                            alt="Logo"
                          />
                        </Link>
                      </div>
                      <div
                        className="mobile-navigation-icon"
                        id="mobile-menu-trigger"
                        onClick={this.activeMobileMenu}
                        style={{
                          fontSize: "24px",
                          width: "60px",
                          height: "20px",
                        }}
                      >
                        <i />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header info area =======*/}
            {/*=======  header navigation area  =======*/}
            <div className="header-navigation-area default-bg">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    {/* header navigation */}
                    <div className="header-navigation header-navigation--header-default position-relative">
                      <div className="header-navigation__nav position-static">
                        <nav>
                          <ul>
                            <li>
                              <Link to={`/`}> HOME </Link>
                            </li>
                            <li className="has-children has-children--multilevel-submenu">
                              <Link to={``}>ABOUT</Link>
                              <ul className="submenu">
                                <li>
                                  <Link to={`/vision`}>
                                    Vision, Mission, Core Values
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/background`}>
                                    History and Major Roles
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/orgonogram`}>
                                    Organization Structure
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/principal`}>
                                    Message from the Principal
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/leaders`}>
                                    FTI Top Leadership
                                  </Link>
                                </li>
                                {/* <li> */}
                                {/*   <Link to={`/board`}>Advisory Board</Link> */}
                                {/* </li> */}
                                <li>
                                  <Link to={`/team`}>Management Staff</Link>
                                </li>
                              </ul>
                            </li>
                            <li className="has-children has-children--multilevel-submenu">
                              <Link to={``}>ADMISSION</Link>
                              <ul className="submenu">
                                <li>
                                  <Link to={`/programs`}>Programs Offered</Link>
                                </li>
                                <li>
                                  <Link to={`/how-to-apply`}>How to Apply</Link>
                                </li>
                                <li>
                                  <Link to={`/online`}>Online Application</Link>
                                </li>
                              </ul>
                            </li>
                            <li className="has-children has-children--multilevel-submenu">
                              <Link to={``}>ACADEMICS</Link>
                              <ul className="submenu">
                                <li>
                                  <Link to={`/departments`}>
                                    Academic Departments
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/short-courses`}>
                                    Short Courses
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="https://saris.maliasili.go.tz"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    SARIS
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/students`}>
                                    Students' Welfare
                                  </Link>
                                </li>
                              </ul>
                            </li>
                            <li className="has-children has-children--multilevel-submenu">
                              <Link to={``}>UNITS</Link>
                              <ul className="submenu">
                                <li>
                                  <Link to={`/ict-services`}>ICT</Link>
                                </li>
                                <li>
                                  <Link to={`/library`}>Library Services</Link>
                                </li>
                                <li>
                                  <Link to={`/apiary`}>Procurement</Link>
                                </li>
                                <li>
                                  <Link to={`/sawmill`}>
                                    Short Courses Research and Consultancy
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/tree-nursery`}>
                                    Finance and Accounts
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/training-forest`}>
                                    Planning and Business
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/training-forest`}>
                                    Medical Services
                                  </Link>
                                </li>
                                <li>
                                  <Link to={`/training-forest`}>Estate</Link>
                                </li>
                              </ul>
                            </li>
                            {/* <li> */}
                            {/*   <Link to={`/research-and-consultancy`}> */}
                            {/*     RESEARCH & CONSULTANCY */}
                            {/*   </Link> */}
                            {/* </li> */}
                            <li>
                              <Link to={`/news-left-sidebar`}>
                                {" "}
                                NEWS AND PUBLICATION
                              </Link>
                            </li>
                            <li>
                              <Link to={`/gallery`}>GALLERY</Link>
                            </li>
                            <li>
                              <Link to={`/contact-us`}>CONTACT</Link>{" "}
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*=======  End of header navigation area =======*/}
          </div>
        </div>
        {/*====================  End of header area  ====================*/}

        {/* Mobile Menu */}
        <MobileMenu ref={this.mobileMenuElement} />

        <Outlet />
      </div>
    );
  }
}

export default NavBar;
